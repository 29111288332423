import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {useAuth} from '../modules/auth'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

const PrivateRoutes = () => {
  const CategorysPage = lazy(() => import('../modules/apps/category/Page'))
  const PostsPage = lazy(() => import('../modules/apps/post/Page'))
  const UsersPage = lazy(() => import('../modules/apps/user/Page'))
  const FileUpload = lazy(() => import('../modules/apps/media/Page'))
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {currentUser && currentUser.role === 'admin' ? (
          <>
            <Route
              path='category/*'
              element={
                <SuspensedView>
                  <CategorysPage />
                </SuspensedView>
              }
            />

            <Route
              path='post/*'
              element={
                <SuspensedView>
                  <PostsPage />
                </SuspensedView>
              }
            />
            <Route
              path='upload_file/*'
              element={
                <SuspensedView>
                  <FileUpload />
                </SuspensedView>
              }
            />
            <Route
              path='user/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
          </>
        ) : (
          <Route path='*' element={<Navigate to='/dashboard' />} />
        )}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
