import axios from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {AuthModel, BusinessModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REFRESH_URL = `${API_URL}/auth/login_using_refresh_token`
export const SWITCH_BUSINESS = `${API_URL}/auth/login_to_business_with_token`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const REQUEST_BUSINESS_URL = `${API_URL}/get_business`
export const GOOGLE_LOGIN_URL = `${API_URL}/auth/login_with_google`

export async function loginWithGoogle(value: any): Promise<any> {
  return await axios.post(GOOGLE_LOGIN_URL, value)
}
// Server should return AuthModel
export async function login(email: string, password: string, role: string): Promise<any> {
  return await axios.post(LOGIN_URL, {
    email,
    password,
    role,
  })
}
// Server should return AuthModel
export async function switchBusiness(token: string, business_id: ID): Promise<AuthModel> {
  return await axios.post(SWITCH_BUSINESS, {
    token,
    business_id,
  })
}
export async function refreshToken(refresh_token: string): Promise<AuthModel> {
  return await axios.post(REFRESH_URL, {
    refresh_token,
  })
}

// Server should return AuthModel
export async function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  const res = (await axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })) as AuthModel
  return res
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function getUserByToken() {
  const res = (await axios.get(GET_USER_BY_ACCESSTOKEN_URL)) as UserModel
  return res
}

export async function getBusiness(business_id?: ID) {
  const res = (await axios.get(
    REQUEST_BUSINESS_URL + (business_id ? '?business_id=' + business_id : '')
  )) as BusinessModel
  return res
}
